const initial = {
  data: null,
  isLoading: false,
  isTabLoading: false,
  visitsBySelectedDate: null,
  doctors: null,
  selectedVisit: null,
  messageList: [],
  unreadMessageCount: 0,
};

const CardReducer = (state = initial, action) => {
  switch (action.type) {
    case "SET_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
    case "SET_TAB_LOADING":
			return {
				...state,
				isTabLoading: action.payload,
			};
    case "GET_PATIENT_DATQA":
      return {
        ...state,
        data: {
          ...action.payload,
          probes: state.data?.probes,
          visits: state.data?.visits,
          past_visits: state.data?.past_visits,
          future_visits: state.data?.future_visits,
        }
      };
    case "GET_PATIENT_PROBES":
      return {
        ...state,
        data: {
          ...state.data,
          probes: action.payload.probes
        },
      };
    case "GET_PATIENT_VISITS":
      return {
        ...state,
        data: {
          ...state.data,
          visits: action.payload.visits,
          past_visits: action.payload.past_visits,
          future_visits: action.payload.future_visits,
        },
      };
    case "DELETE_FUTURE_VISIT":
      const futureVisits = state.data.future_visits;
      let visitIndex;
      futureVisits.map((visit, vIndex) => {
        if (visit.id === action.payload) {
          visitIndex = vIndex;
        }
      });
      futureVisits.splice(visitIndex, 1);
      return {
        ...state,
        data: {
          ...state.data,
          future_visits: futureVisits,
        },
      };
    case "UPDATE_DOCTOR_NOTE":
      let notes = state.data.notes;
      notes.push(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          notes,
        },
      };
    case "DELETE_DOCTOR_NOTE":
      let doctorNotes = state.data.notes;
      let ind;
      doctorNotes.map((note, nIndex) => {
        if (note.id === action.payload) {
          ind = nIndex;
        }
      });
      doctorNotes.splice(ind, 1);
      return {
        ...state,
        data: {
          ...state.data,
          notes: doctorNotes,
        },
      };
    case "REMOVE_ATTACHMENT_FILE":
      let files = state.data.files;
      let index;
      files.map((file, fIndex) => {
        if (file.id === action.payload) {
          index = fIndex;
        }
      });
      files.splice(index, 1);
      return {
        ...state,
        data: {
          ...state.data,
          files: files,
        },
      };
    case "ADD_ATTACHMENT_FILE":
      let attachmentFiles = state.data.files;
      attachmentFiles.push(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          files: attachmentFiles,
        },
      };
    case "GET_VISITS_BY_SELECTED_DATE":
      return {
        ...state,
        visitsBySelectedDate: action.payload,
      };
    case "ADD_FUTURE_VISITS":
      let fVisits = state.data.future_visits;
      fVisits.push(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          future_visits: fVisits,
        },
      };
    case "UPDATE_FUTURE_VISITS":
      let uFutureVisits = state.data.future_visits;
      let ufIndex;
      uFutureVisits.map((visit, index) => {
        if (visit.id === action.payload.id) {
          ufIndex = index;
        }
      });
      uFutureVisits.splice(ufIndex, 1, action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          future_visits: uFutureVisits,
        },
      };
    case "GET_DOCTOR_LIST":
      return {
        ...state,
        doctors: action.payload,
      };
    case "SELECT_VISIT":
      return {
        ...state,
        selectedVisit: action.payload,
      };
    case "DELETE_SELECTED_VISIT":
      return {
        ...state,
        selectedVisit: null,
      };
    case "ADD_ORDER_SET_DATA":
      let patient_sets_orders = state.data.patient_sets_orders;
      patient_sets_orders.push(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          patient_sets_orders,
        },
      };
    case "ADD_ORDER_COLLECT_DATA":
      let probe_collects = state.data.probe_collects;
      probe_collects.push(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          probe_collects,
        },
      };
    case "GET_MESSAGES_LIST":
      let tempNum = 0;
      action.payload.map(item => {
        if (item.read_at === null || item.read_at === '')
          tempNum ++;
      });
      return {
        ...state,
        messageList: action.payload,
        unreadMessageCount: tempNum,
      };
    case "READ_MESSAGE":
      let oldMessages = [...state.messageList];
      console.log(oldMessages);
      oldMessages.map((item, index) => {
        if (item.id === action.payload.id) {
          oldMessages[index] = {...action.payload};
        }
      });
      return {
        ...state,
        messageList: oldMessages,
        unreadMessageCount: state.unreadMessageCount - 1,
      };
    case "SET_SELECTED_SUB_TAB":
      return {
        ...state,
        selectedSubTab: action.payload,
      };
    default:
      return state;
  }
};
export default CardReducer;
